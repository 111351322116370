import React, { useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';

export default function UserMetaData({ genderRef, ageRef, nativeRef }) {
	return (
		<>
			<div className="selected_gr">
				<div className="selected-gr-item">
					<label htmlFor="gender">Kön</label>
					<Form.Select
						defaultValue={
							sessionStorage.getItem('gender')
								? sessionStorage.getItem('gender')
								: ''
						}
						ref={genderRef}
						name="gender"
						id="gender"
						className=""
					>
						<option value="Välj">Välj</option>
						<option value="Annat">Annat</option>
						<option value="Man">Man</option>
						<option value="Kvinna">Kvinna</option>
					</Form.Select>
				</div>

				<div className="selected-gr-item">
					<label htmlFor="age">Ålder</label>
					<Form.Select
						defaultValue={
							sessionStorage.getItem('age') ? sessionStorage.getItem('age') : ''
						}
						ref={ageRef}
						name="age"
						id="age"
						className=""
					>
						<option value="Välj">Välj</option>
						<option value="10-19">10-19</option>
						<option value="20-29">20-29</option>
						<option value="30-39">30-39</option>
						<option value="40-49">40-49</option>
						<option value="50+">50+</option>
					</Form.Select>
				</div>

				<div className="selected-gr-item">
					<label htmlFor="native">Modersmål</label>
					<Form.Select
						defaultValue={
							sessionStorage.getItem('native')
								? sessionStorage.getItem('native')
								: ''
						}
						ref={nativeRef}
						name="native"
						id="native"
						className=""
					>
						<option value="Välj">Välj</option>
						<option value="Svenska">Svenska</option>
						<option value="Arabiska">Arabiska</option>
						<option value="Bosniska / Kroatiska / Serbiska">
							Bosniska / Kroatiska / Serbiska
						</option>
						<option value="Kurdiska">Kurdiska</option>
						<option value="Polska">Polska</option>
						<option value="Somaliska">Somaliska</option>
						<option value="Afghanska">Afghanska</option>
						<option value="Ukrainska">Ukrainska</option>
						<option value="Annat">Annat</option>

					</Form.Select>
				</div>
			</div>
		</>
	);
}
