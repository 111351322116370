import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { ReactMediaRecorder } from 'react-media-recorder';
import useTextList from '../../Components/Texter/useTextList';
import './Record.css';
import { TbMicrophone } from 'react-icons/tb';
import { FaStop } from 'react-icons/fa';

import { TbCloudUpload } from 'react-icons/tb';
import { IoMdRefresh } from 'react-icons/io';
import Form from 'react-bootstrap/Form';
import * as FirebaseService from '../../Services/FirebaseService';
import Spinner from 'react-bootstrap/Spinner';
import AudioCard from './AudioCard';
import UserMetaData from './UserMetaData';
import Menu from '../../Components/Menu/Menu';

export default function Record() {
	const ageRef = useRef();
	const genderRef = useRef();
	const nativeRef = useRef();
	const navigate = useNavigate();
	const [audioBlob, setAudioBlob] = useState();
	const [loading, setLoading] = useState(false);
	const [randomText, setRandomText] = useState();
	const [recCount, setRecCount] = useState(0);
	const [recSession, setRecSession] = useState([]);
	const [showAll, setShowAll] = useState(false);
	const [recShown, setRecShown] = useState(showAll ? recSession.length : 4);
	const [subAmount, setSubAmount] = useState(0);
	const [removingIndex, setRemovingIndex] = useState();
	const [latestRec, setLatestRec] = useState();

	const { textList } = useTextList();

	const random = Math.floor(Math.random() * textList.length);

	function handleTerms(e) {
		e.preventDefault();

		window.open(
			'/voice-data-terms',
			'User Terms',
			'width=700, height=900',
			'resizable,scrollbars,status'
		);
	}

	function deleteRec(index, id) {
		setRemovingIndex(id);
		setTimeout(() => {
			recSession.splice(index, 1);
			setRecSession((prevState) => [...prevState]);
			if (showAll) {
				setRecShown(recShown - 1);
			} else {
				setRecShown(4);
			}
		}, 500);
	}

	async function continueRecording(readText, blob) {
		let blobUrl = URL.createObjectURL(blob);
		try {
			setRecCount(recCount + 1);
			setLatestRec(recCount + 1)
			setRecSession((prevState) => [
				...prevState,
				{
					id: recCount + 1,
					text: readText,
					blobFile: blob,
					blobUrl: blobUrl,
				},
			]);
			if (showAll) {
				setRecShown(recShown + 1);
			} else {
				setRecShown(4);
			}
		} catch (e) {
			console.log(e.message);
		}
	}

	function generateId() {
		const date = new Date()
			.toLocaleDateString('sv-SE')
			.slice(2)
			.replaceAll('-', '');
		const timestamp = new Date()
			.toLocaleTimeString('sv-SE')
			.replaceAll(':', '');
		let recId = date + timestamp;

		return recId;
	}

	useEffect(() => {
		setRandomText(textList[random]);
		// eslint-disable-next-line
	}, [textList]);

	useEffect(() => {
		setRandomText(textList[random]);
		// eslint-disable-next-line
	}, [recCount]);

	async function handleSubmit(e) {
		setLoading(true);
		e.preventDefault();
		let addToFirestore = false;
		let id = await generateId();
		try {
			for (const [i, session] of recSession.entries()) {
				const newId = id + `_${i}`;
				setSubAmount(i + 1);
				addToFirestore = await FirebaseService.addDoc(
					newId,
					session.text,
					session.blobFile,
					genderRef.current.value,
					ageRef.current.value,
					nativeRef.current.value,
					id
				);
			}
			setLoading(false);
			if (addToFirestore) {
				navigate('/finished', {
					state: {
						id: id,
						rec_amount: recSession.length,
					},
				});
			}
		} catch (e) {
			console.log(e.message);
			setLoading(false);
		}
	}

	return (
		<>
			<Menu recSession={recSession} randomText={randomText} />
			<Container style={{ cursor: loading ? 'wait' : 'auto' }}>
				<div>
					<ReactMediaRecorder
						audio
						onStop={(blobUrl, blob) => {
							setAudioBlob(blob);

							continueRecording(randomText, blob);
						}}
						render={({
							status,
							startRecording,
							stopRecording,
							mediaBlobUrl,
							clearBlobUrl,
							error,
						}) => (
							<>
								<div className="text_rec_wrapper">
									<div className="text_card">
										{randomText ? <p>{randomText}</p> : null}
										{status === 'recording' ? null : (
											<div className="new_text_wrapper">
												<button
													className="new_text_btn"
													onClick={async () => {
														await setRandomText(null);
														setRandomText(textList[random]);
													}}
												>
													<IoMdRefresh size={25} />
												</button>
											</div>
										)}
									</div>
									<small className="mt-3 text-center">
										När du spelar in, läs gärna upp texten naturligt som om du
										pratar med en kompis. Ju fler inspelningar desto bättre.
									</small>{' '}
									{status === 'recording' ? (
										<>
											<button
												onClick={async () => {
													stopRecording();
													await setRandomText(null);
													setRandomText(textList[random]);
												}}
												className="rec_btn_layout recording_btn_active"
											>
												<FaStop />
											</button>
											<small className="mb-4">
												Tryck igen för att avsluta inspelning.
											</small>{' '}
											{process.env.REACT_APP_DEV_TESTING === 'true' ? (
												<div className="dev_text text-center mb-3">
													<div className="text-center">TEST MODE</div>
												</div>
											) : null}
										</>
									) : (
										<>
											<button
												onClick={() => {
													startRecording();
													clearBlobUrl();
												}}
												className="rec_btn_layout record_btn"
												disabled={!randomText}
											>
												<TbMicrophone />
											</button>
											<small className="mb-4">Tryck för att spela in.</small>
											{process.env.REACT_APP_DEV_TESTING === 'true' ? (
												<div className="dev_text text-center mb-3">
													<div className="text-center">TEST MODE</div>
												</div>
											) : null}
										</>
									)}
								</div>

								<div className="">
									{recSession.length > 0 ? (
										<>
											<small className="text-center d-flex justify-content-center"></small>
											<hr />
											<div className="rec_session_container">
												{recSession
													.sort((a, b) => (b.id > a.id ? 1 : -1))
													.slice(0, recShown)
													.map((session, i) => (
														<AudioCard
															i={i}
															id={session.id}
															session={session}
															deleteRec={deleteRec}
															removingIndex={removingIndex}
															latestRec={latestRec}
														/>
													))}
											</div>
											<small className="text-center fw-bold d-flex justify-content-center mt-5">
												{recSession.length < 4 ? (
													<>
														Visar {recSession.length} av {recSession.length}
													</>
												) : (
													<>
														Visar {recShown} av {recSession.length}
													</>
												)}
											</small>
											<div className="d-flex justify-content-center mt-3">
												<div>
													{!showAll && recShown < recSession.length ? (
														<>
															<button
																onClick={() => {
																	setRecShown(recSession.length);
																	setShowAll(true);
																}}
																className="btn_layout_xs btn_secondary"
															>
																Visa alla
															</button>
														</>
													) : null}

													{showAll && recShown > 4 ? (
														<>
															<button
																onClick={() => {
																	setRecShown(4);
																	setShowAll(false);
																}}
																className="btn_layout_xs btn_secondary"
															>
																Visa mindre
															</button>
														</>
													) : null}
												</div>
											</div>
											<div className="form_wrapper">
												<Form
													className="form_wrapper"
													onSubmit={(e) => handleSubmit(e)}
												>
													<UserMetaData
														genderRef={genderRef}
														nativeRef={nativeRef}
														ageRef={ageRef}
													/>
													<Form.Group
														className=""
														controlId="formBasicCheckbox"
													>
														<div className="check-grid">
															<div className="check-item">
																<Form.Check
																	required
																	type="checkbox"
																	className="signup-checkbox"
																	style={{ display: 'inline' }}
																/>
															</div>

															<div className="check-item check-item-2">
																<p className="checkbox-text">
																	Jag godkänner{' '}
																	<button
																		onClick={handleTerms}
																		className="link-btn"
																		type="button"
																	>
																		villkoren för insamling av röstdata
																	</button>
																</p>
															</div>
														</div>
													</Form.Group>

													<button
														type="submit"
														className="btn_layout btn_blue mt-4 mb-5"
														style={{ position: 'relative', width: '350px' }}
														disabled={loading}
													>
														{loading && (
															<div
																className="button_progress"
																style={{
																	width: `${
																		(subAmount / recSession.length) * 100
																	}%`,
																}}
															></div>
														)}
														<span className="button_text">
															{' '}
															<TbCloudUpload className="me-2" size={25} />
															{loading
																? `Skickar in ${subAmount} av ${recSession.length}`
																: 'Skicka in'}
														</span>
													</button>
												</Form>
											</div>
										</>
									) : null}
								</div>
							</>
						)}
					/>
				</div>
			</Container>
		</>
	);
}
