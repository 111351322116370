import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import { MdRecordVoiceOver } from 'react-icons/md';
import { FiChevronDown } from 'react-icons/fi';
import Modal from 'react-bootstrap/Modal';
import hbg from '../../Assets/Images/hbg_logo.png';
import nordaxon from '../../Assets/Images/nordaxon_logo.png';
import { AiFillHeart } from 'react-icons/ai';

export default function Home() {
	const navigate = useNavigate();
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			<Container className="home_container">
				<div className="headline_wrapper">
			

					<h1 className="headline_title">Babelfisk</h1>
					<p className="headline_sub_text text-center">
						Insamling av röstdata
					</p>
					<button
						className="btn_layout btn_blue shadow"
						onClick={() => navigate('/record')}
						style={{ animation: 'fade-in 400ms forwards', width: '320px' }}
						
					>
						<MdRecordVoiceOver className="me-2" size={20} /> Donera din röst
					</button>
					<div className="d-flex justify-content-center">
						<button
							className="d-block mt-2 link-btn"
							style={{ animation: 'fade ease-in-out 1000ms' }}
							onClick={() => setShow(true)}
						>
							Varför ska jag donera min röst?
						</button>
					</div>

					{/* 		<div className="logo_gr">
						<div className="logo-gr_item_1">
							<img src={hbg} alt="hbg-logo" width="80px" />
						</div>

						<div className="logo-gr_item_2">
							<img src={nordaxon} alt="nordaxon-logo" width="80px" />
						</div>
					</div> */}
					{process.env.REACT_APP_DEV_TESTING === 'true' ? (
						<div className="dev_text text-center mb-3 mt-5">
							<div className="text-center">TEST MODE</div>
						</div>
					) : null}
					<Modal size="md" show={show} onHide={handleClose}>
						<Modal.Body className="info_modal">
							<h1 className="info_header">
								Hjälp oss förbättra framtidens tal-till-text
							</h1>

							<p className="info_text">
								Med hjälp av språket uttrycker vi oss och våra behov i samspel
								med andra. Kunskap, känslor, minnen och erfarenheter förmedlas
								genom tal och av denna anledning spelar språket en central roll
								i vårt liv. Med hjälp av språket kan vi berätta om vilka vi är,
								beskriva våra känslor, påverka andra, utveckla varandras tankar
								och skapa relationer. Utan ett språk minimeras vår värld och
								våra möjligheter.
							</p>

							<p className="info_text">
								Babelfisk är ett initiativ för att undersöka hur vi kan kan få
								maskiner att bli bättre på att förstå alla olika uttal av
								svenska. För att kunna göra detta behövs många exempel på hur
								olika människor låter när vi pratar svenska.
								<br />
								<br />
								På denna sida kan du hjälpa oss att förbättra framtidens
								digitala tjänster genom att donera din röst. För att ni ska
								känna er trygga kräver vi ingen personligdata mer än er röst.
								Det är även lätt att ta bort sin röst i efterhand om man skulle
								ångra sig.{' '}
							</p>

							<div className="mt-5 d-flex justify-content-center">
								<button
									className="btn_layout btn_blue shadow"
									onClick={() => navigate('/record')}
									
								>
									<AiFillHeart className="me-2" size={20} /> Jag vill hjälpa
									till
								</button>
							</div>
						</Modal.Body>
					</Modal>

					{/* 		<p className="mt-2 created_by">
						Denna sida är skapad med ❤️ i Malmö av{' '}
						<a
							className="info_link"
							href="https://www.nordaxon.com/"
							target="_blank"
							rel="noopener noreferrer"
						>
							NordAxon
						</a>
					</p> */}
				</div>
			</Container>
		</>
	);
}
