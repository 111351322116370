import React, { useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import './Finished.css';
import { AiFillHeart } from 'react-icons/ai';
import { IoMail } from 'react-icons/io5';
import { IoMailOpen } from 'react-icons/io5';

import sendTemplateEmail from './emailApi';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';

export default function Finished() {
	const location = useLocation();
	const emailRef = useRef(null);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [mailSent, setMailSent] = useState(false);
	const [show, setShow] = useState(false);
	const id = location.state.id;
	const recAmount = location.state.rec_amount;
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleForm = async (event) => {
		try {
			setMailSent(false);
			setLoading(true);
			event.preventDefault();
			const email = emailRef.current.value;
			const ref_id = id;
			await sendTemplateEmail(email, ref_id, recAmount);
			handleClose();
			setMailSent(true);
			setLoading(false);
		} catch (e) {
			setLoading(false);
			setMailSent(false);
			alert(e.message);
			console.log(e.message);
		}
	};

	return (
		<>
			<div className="">
				<Container className="finished_container">
					<h1 className="thanks_header text-center">Tack för ditt bidrag!</h1>
					<div className="ref_nr text-center">
						<p>
							Referensnummer: <b> {id}</b>
						</p>
					</div>
					<p>
						Ditt bidrag innehåller <b> {recAmount} </b>{' '}
						{recAmount === 1 ? 'inspelning' : 'inspelningar'}. Vi är väldigt
						tacksamma att du ville vara med och bidra till förbättringen av
						speech to text. Vill du ta bort ditt bidrag från databasen, kontakta
						oss på
						<a className="link-btn" href="mailto:info@nordaxon.com">
							{' '}
							info@nordaxon.com
						</a>{' '}
						och ange ditt referensnummer.
					</p>
					<div className="d-flex justify-content-center mb-5">
						<button
							className="btn_layout btn_blue mt-3"
							onClick={() => navigate('/record')}
							style={{ width: '330px'}}
						>
							<AiFillHeart className="me-2" size={20} />
							Donera igen
						</button>
					</div>

					<hr />

					<div className="ref_to_mail">
						<div className="submit_mail">
							<h1 className="to_mail_headline text-center ">
								Referensnumret till din mail
							</h1>
							<form
								className="ref_to_mail_form d-flex justify-content-center mb-1"
								onSubmit={handleForm}
							>
								{mailSent ? (
									<>
										<p>
											Referensnumret är skickat till den angivna mailadressen.
										</p>
									</>
								) : (
									<>
										<input
											className="input-field shadow-sm"
											type={'email'}
											placeholder={'mail@example.com'}
											ref={emailRef}
											required
										></input>{' '}
										<button
											className="btn_layout_mail btn_black"
											type="submit"
											disabled={loading}
										>
											{loading ? <>Skickar...</> : <>Skicka</>}
										</button>{' '}
									</>
								)}
							</form>
							{!mailSent && (
								<small className="text-center">
									Din mailadress kommer inte att sparas
								</small>
							)}
						</div>
					</div>
				</Container>
			</div>
		</>
	);
}
