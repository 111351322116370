import './css/main.css';
import Home from './Navigation/Home/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Record from './Navigation/Record/Record';
import Finished from './Components/Finished/Finished';
import Terms from './Components/Terms/Terms';
import { AiFillWarning } from 'react-icons/ai';

function App() {
	return (
		<>
	

			<BrowserRouter>
				

				<div className="main-container">
					<Routes>
						<Route exact path="/" element={<Home />} />
						<Route exact path="/record" element={<Record />} />
						<Route exact path="/finished" element={<Finished />} />
						<Route exact path="/voice-data-terms" element={<Terms />} />
					</Routes>
				</div>
			</BrowserRouter>
		</>
	);
}

export default App;
