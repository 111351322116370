import React, { useState, useRef } from 'react';

import { MdOutlineClose } from 'react-icons/md';
import { FiPlay } from 'react-icons/fi';
import { GrStop } from 'react-icons/gr';

export default function AudioCard({
	session,
	i,
	deleteRec,
	id,
	removingIndex,
	latestRec,
}) {
	const playerRef = useRef();

	const [playing, setPlaying] = useState(false);

	return (
		<>
			<div
				className={
					id === removingIndex
						? 'audio_card fade-out'
						: id === latestRec
						? 'audio_card latest-rec'
						: id === latestRec && removingIndex
						? 'audio_card latest-rec fade-out'
						: 'audio_card'
				}
				id={`aud-card_${i}`}
				key={id}
			>
				<div className="audio_player_wrapper">
					<span className="audio_card_btn">{session.id}</span>
					{playing ? (
						<span
							className="audio_btn"
							onClick={() => {
								const sound = document.getElementById(`audio_${i}`);
								sound.pause();
								sound.currentTime = 0;
							}}
						>
							<GrStop size={25} />
						</span>
					) : (
						<span
							className="audio_btn"
							onClick={() => {
								document.getElementById(`audio_${i}`).play();
							}}
						>
							<FiPlay size={25} />
						</span>
					)}
					<p>{session.text}</p>
					<audio
						id={`audio_${i}`}
						ref={playerRef}
						src={session.blobUrl}
						controls
						onEnded={() => setPlaying(false)}
						onPlay={() => setPlaying(true)}
						onPause={() => setPlaying(false)}
						hidden
					/>{' '}
					<span className="audio_btn" onClick={() => deleteRec(i, id)}>
						<MdOutlineClose size={25} />
					</span>
				</div>
			</div>
		</>
	);
}
