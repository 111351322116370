import React from 'react';
import { useNavigate } from 'react-router-dom';
import { db, storage } from '../firebase';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import {
	ref,
	getDownloadURL,
	uploadBytesResumable,
	deleteObject,
} from 'firebase/storage';

export const addDoc = async (
	id,
	text,
	blob,
	gender,
	age,
	native_language,
	contribution_id
) => {
	const storagePath = `${process.env.REACT_APP_RECORDING_COLLECTION}/${id}`;
	sessionStorage.setItem('gender', gender);
	sessionStorage.setItem('age', age);
	sessionStorage.setItem('native', native_language);
	try {
		const docRef = doc(db, process.env.REACT_APP_RECORDING_COLLECTION, id);
		const storageRef = ref(storage, storagePath);
		const uploadTask = uploadBytesResumable(storageRef, blob);

		//await upload before continue
		await uploadTask.then();

		//get storage url
		const audioUrl = await getDownloadURL(storageRef);

		await setDoc(docRef, {
			created: serverTimestamp(),
			id: id,
			text: text,
			gender: gender,
			age: age,
			native_language: native_language,
			is_second_language: native_language === 'Svenska' ? false : true,
			verified: false,
			media_url: audioUrl,
			contribution: contribution_id,
		});

		console.log('Successfully added to firestore.');
		return true;
	} catch (e) {
		console.log(e.message);
		alert(e.message);
		return false;
	}
};
