import React, { useState, useEffect } from 'react';
import texter from './texter.txt';

/* ---- All available native languages and their iso code ---- */
const useTextList = () => {
	const [textArray, setTextArray] = useState([]);

	useEffect(() => {
		fetch(texter)
			.then((response) => response.text())
			.then((myText) => {
				const formattedText = myText.replaceAll('\r', '');
				setTextArray(formattedText.split('\n'));
			});
	}, []);

	const textList = textArray;

	return {
		textList,
	};
};

export default useTextList;
