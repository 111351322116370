import React from 'react';
import Container from 'react-bootstrap/Container';
import './Menu.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import { TbMicrophone } from 'react-icons/tb';

export default function NavbarMenu({ recSession, randomText }) {
	const navigate = useNavigate();
	let location = useLocation();

	return (
		<>
			<div className="navbar">
				<Container className="nav-gr_container">
					<div className="nav-gr_item">
						<button
							onClick={() => navigate('/')}
							className="back_btn"
							type="button"
						>
							<MdArrowBack className="" size={20} color={'black'} />{' '}
						</button>
					</div>
					<div
						className="nav-gr_item me-3"
						style={{
							fontSize: '1.2rem',
							fontWeight: '500',
						}}
					>
						<TbMicrophone size={25} color="var(--mainColor)" />{' '}
						{randomText ? (
							<span
								className="recording_count"
								style={{
									fontWeight: '600',
								}}
							>
								{recSession.length > 0 ? recSession.length : 0}
							</span>
						) : null}
					</div>
				</Container>
			</div>
		</>
	);
}
