import React from 'react';
import Container from 'react-bootstrap/Container';
import './Terms.css';

export default function Terms() {
	return (
		<>
			<Container className="terms_container">
				<h1 className="terms_header">Villkor för insamling av röstdata</h1>
				<p className="terms_text">
					Genom att skicka in ditt bidrag samtycker du till dessa villkor.
				</p>

				<h2 className="">Röstdata</h2>
				<p className="terms_text">
					När du skickar in din röstdata godkänner du att vi sparar din
					inspelning i vår databas. Din röstdata används enbart i avseende att
					kunna analysera och förbättra vår speech to text modell. Förutom din
					röstdata så används inga andra personliga identifierare som kan koppla
					din röstdata specifikt till dig.
					<br />
					<br />
					Du kan när som helst välja att radera din röstdata genom att kontakta
					oss på info@nordaxon.com och ange <b>referensnumret </b> 
					som tilldelas efter att du skickat in ditt bidrag.
				</p>
			</Container>
		</>
	);
}
