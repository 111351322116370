import axios from 'axios'

const emailSendUrl = process.env.REACT_APP_SEND_MAIL_URL;

export default async function sendTemplateEmail(toEmail, ref_id, recAmount) {
    return axios({
        method: 'post',
        url: emailSendUrl,
        data: {
            "email": toEmail,
            "ref_id": ref_id,
            "num_of_rec": recAmount
        },
        headers:{
            "Content-Type": "application/json"
        }
    })
}   